import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { removePlaceholder } from "../components/helperFunctions"

const ResourcesContent = () => (
  <StaticQuery
    query={graphql`
      query ResourcesQuery {
        resources: allContentfulResources {
          edges {
            node {
              title
              file {
                file {
                  url
                }
              }
              placeholder
            }
          }
        }
      }
    `}
    render={data => {
      const allResourcesArray = data.resources.edges
      const allResourcesArrayFiltered = removePlaceholder(allResourcesArray)
      console.log("ALL", allResourcesArrayFiltered)

      if (allResourcesArrayFiltered && allResourcesArrayFiltered.length >= 1) {
        const resourceElement = allResourcesArrayFiltered.map(
          (resource, index) => {
            return (
              <a
                href={resource.node.file.file.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
                className="downloadsPage__link"
              >
                {resource.node.title}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="downloadsPage__icon"
                >
                  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
              </a>
            )
          }
        )
        return <div>{resourceElement}</div>
      } else {
        return <p>Files unavailable for download</p>
      }
    }}
  />
)

export default ResourcesContent
