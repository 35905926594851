import React, { useState } from "react"
import PageTitle from "../../components/shared/PageTitle"
import ResourcesContent from "../../components/resourcesContent"

/* NOTE: this page is not configured to store sensitive information. The password protection is not for this purpose. Do not place any information on this page that cannot be publicly accessible. */

const Resources = () => {
  const [password, setPassword] = useState("")
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const correctPassword = "slmha2024"

  const handleSubmit = e => {
    e.preventDefault()
    if (password === correctPassword) {
      setIsAuthenticated(true)
    } else {
      alert("Incorrect password. Please try again.")
    }
  }

  const handleLogout = e => {
    e.preventDefault()
    setIsAuthenticated(false)
  }

  return (
    <div>
      {isAuthenticated ? (
        <div className="grid mt-10">
          <div className="">
            <PageTitle>Admin Downloads</PageTitle>

            <div className="downloadsPage__table">
              <ResourcesContent />
            </div>
          </div>
          <form className="justify-self-center mt-10">
            <button
              onClick={handleLogout}
              className="bg-primary hover:bg-logoLightBlue text-white font-bold py-2 px-4 rounded mt-2"
            >
              Log Out
            </button>
          </form>
        </div>
      ) : (
        <div>
          <PageTitle>Admin Download Page</PageTitle>
          <div className="flex justify-center">
            <form onSubmit={handleSubmit} className="grid gap-x-4 font-bold">
              <input
                type="text"
                id="text"
                placeholder="Password"
                value={password}
                className="border rounded p-1"
                onChange={e => setPassword(e.target.value)}
              />
              <button
                type="submit"
                className="bg-primary hover:bg-logoLightBlue text-white font-bold py-1 px-4 rounded mt-4 justify-self-end"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Resources
